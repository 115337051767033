import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/800home/Logo.webp';
import { Envelope, EnvelopeFill, Facebook, GeoAlt, Globe, Instagram, Linkedin, Phone, Telephone, TelephoneFill, Tiktok, Whatsapp } from 'react-bootstrap-icons';


const ProfileMobile = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <section id="website-responsive" className="container-lg py-5">
      <div className="row justify-content-center align-items-center text-center">
        <div className="col-11 col-md-10z">
          <img src={Logo} className={`w-logo1-mb d-block mx-auto ${props.DisplayMobile}`} alt="" />
          <div className="py-3" />
          <img src={props.Image} className='w-logo2-mb d-block mx-auto' alt="" />
          <div className="py-3" />
          <div className="text-white fs-ss-35 fw-500 lh-sm ">
          {props.Title}
          </div>
          <div className="text-ss-primary fs-ss-18 fw-400">
          {props.Designation}
          </div>
          <div className={`py-2 ${props.DisplayMobile} `} />
          <div className="col-12 col-md-7 mx-auto" >
          <div className={`col-12 col-md-7 mx-auto ${props.DisplayMobile} row fs-ss-20 py-2 text-center border-white border-1 border gx-0 align-items-center`}>
            <a href={`tel:${props.PhoneNumberLink}`} className="col-4 text-decoration-none text-ss-primary text-hover-ss-primary" > 
              <TelephoneFill/>  
              <div className='text-white fs-ss-16 fw-300 mt-1'> Call </div>
            </a>
            <a href={`mailto:${props.Email}`} className="col-4 border-start border-end border-1 border-white py-1 text-decoration-none text-ss-primary text-hover-ss-primary" > 
              <EnvelopeFill/>
              <div className='text-white fs-ss-16 fw-300 mt-1'> Email </div>
            </a>
            <a target="_blank" href={`https://api.whatsapp.com/send/?phone=${props.WhatsappLink}&text&type=phone_number&app_absent=0`} className="col-4 text-decoration-none text-ss-primary text-hover-ss-primary" > 
              <Whatsapp/> 
              <div className='text-white fs-ss-16 fw-300 mt-1'> Whatsapp </div>
            </a>
          </div>
          <div className="py-3" />
          <div className="text-start">
          <div className="border-bottom border-1 border-white pt-3 pb-2"> 
               <a className='text-decoration-none text-white text-hover-ss-primary fw-300' href={`tel:${props.PhoneNumberLink}`} target='_blank'> <Telephone className='text-ss-primary me-2'/> {props.PhoneNumber} </a> 
               </div>
               <div className={`border-bottom border-1 border-white pt-3 pb-2 ${props.DisplayMobile} `}> 
               <a className='text-decoration-none text-white text-hover-ss-primary fw-300' href={`https://api.whatsapp.com/send/?phone=${props.WhatsappLink}&text&type=phone_number&app_absent=0`} target='_blank'> <Whatsapp className='text-ss-primary me-2'/> {props.Whatsapp} </a> 
               </div>
               <div className="border-bottom border-1 border-white pt-3 pb-2"> 
               <a className='text-decoration-none text-white text-hover-ss-primary fw-300' href={`mailto:${props.Email}`} target='_blank'> <Envelope className='text-ss-primary me-2'/> {props.Email} </a> 
               </div>

               <div className="text-white text-hover-ss-primary fw-300 pt-3 pb-md-1 pb-2 border-bottom border-1 border-white"> 
              <GeoAlt className='text-ss-primary me-2'/> 
               1104-72, Xavier Business Center, Ibn Battuta Gate Offices, Dubai, UAE
               </div>
               
               <div className="border-bottom border-1 border-white pt-3 pb-md-1 pb-2"> 
               <a className='text-decoration-none text-white text-hover-ss-primary fw-300' href="https://800homes.ae/" target='_blank'> <Globe className='text-ss-primary me-2'/> https://800homes.ae </a> 
               </div>

                </div>

                <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <div className=''>
              <div className='pt-4 pt-md-5'/>
              <div className="text-center">
              <div className="text-ss-secondary fs-ss-26 text-white">
              <a href="https://www.facebook.com/800Homes" target="_blank" className='text-decoration-none text-grey text-hover-ss-primary' > <Facebook className='me-4'/> </a>
              <a href="https://www.instagram.com/800homesae/" target="_blank" className='text-decoration-none text-grey text-hover-ss-primary' > <Instagram className='me-4'/> </a>
              <a href="https://www.tiktok.com/@800homes" target="_blank" className='text-decoration-none text-grey text-hover-ss-primary' > <Tiktok className='me-4'/> </a>
              <a href="https://www.linkedin.com/company/800-homes-real-estate-brokerage-firm/" target="_blank" className='text-decoration-none text-grey text-hover-ss-primary' > <Linkedin className='me-4'/> </a>
              </div>         
              </div>
            </div>
        </div>
      </div>
    </section>

          </div>
        </div>
      </div>
    </section>
    </>
  );
}

export default ProfileMobile;
