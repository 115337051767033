import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import HomeBanner from '../components/home/HomeBanner';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import { Link } from 'react-router-dom';
import Img1 from '../assets/images/800home/Img1.webp';
import Icon1 from '../assets/images/Icons/1.png';
import Icon2 from '../assets/images/Icons/2.png';
import Icon3 from '../assets/images/Icons/3.png';
import Icon4 from '../assets/images/Icons/4.png';
import Icon5 from '../assets/images/Icons/5.png';
import Icon6 from '../assets/images/Icons/6.png';
import Icon7 from '../assets/images/Icons/7.png';
import Icon8 from '../assets/images/Icons/8.png';
import Icon9 from '../assets/images/Icons/9.png';
import Icon10 from '../assets/images/Icons/10.png';
import Icon11 from '../assets/images/Icons/11.png';
import Icon12 from '../assets/images/Icons/12.png';
import Icon13 from '../assets/images/Icons/13.png';
import Icon14 from '../assets/images/Icons/14.png';
import Icon15 from '../assets/images/Icons/15.png';
import Icon16 from '../assets/images/Icons/16.png';
import Icon17 from '../assets/images/Icons/17.png';
import Icon18 from '../assets/images/Icons/18.png';
import Icon19 from '../assets/images/Icons/19.png';
import Icon20 from '../assets/images/Icons/20.png';
import Testimonials from '../components/home/Testimonials';
import Slider from 'react-slick';
import SingleListing2 from '../components/listing/SingleListing';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import BannerImg from '../assets/images/800home/HomeBanner.webp';
import BgMiddle from '../assets/images/800home/Img2.webp';
import { ChevronDoubleRight, ChevronRight } from 'react-bootstrap-icons';
import HomeSliderListing from '../components/listing/HomeSliderListing';

const Home = () => {
  
  const [records, setRecords] = useState([])
  useEffect(()=>{
  fetch('https://my-json-server.typicode.com/mhaseeb-0/dubai/posts') 
  .then(res => res.json())
  .then(data => setRecords (data))
  .catch(err=> console.log(err))
  }, [])

  const [counterOn, setCounterOn] = useState(false);

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])
  
  var settings = {
  dots: false,
  infinite: true,
  speed: 2000,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 1,
  autoplay: true,
  autoplaySpeed: 10000,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: true,
        dots: false
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: true,
        dots: false
      }
    }
  ]
};
  return (
            <>
        <Helmet>
        <meta charSet="utf-8" />
        <title> Real Estate Services | Buy, Sell, or Rent with 800 Homes </title>
        <meta name="description" content="800 Homes offers expert real estate and consulting services. Buy, sell, or rent properties with trusted agents and hassle-free transactions." />
        
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Real Estate Services | Buy, Sell, or Rent with 800 Homes" />
        <meta property="og:description" content="800 Homes offers expert real estate and consulting services. Buy, sell, or rent properties with trusted agents and hassle-free transactions." />
        <meta property="og:url" content="https://800homes.ae/" />
        <meta property="og:site_name" content="800homes" />
        <meta property="og:image" content="https://800homes.ae/og.webp" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://800homes.ae/og.webp" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="800 Homes offers expert real estate and consulting services. Buy, sell, or rent properties with trusted agents and hassle-free transactions." />
        <meta name="twitter:title" content="Real Estate Services | Buy, Sell, or Rent with 800 Homes" />
        <meta name="twitter:image" content="https://800homes.ae/og.webp"/>
        <link rel="canonical" href="https://800homes.ae/"/>
        </Helmet>

    <section id='website-responsive' className="container-fluid px-0">
    <section className="container-fluid bg-home-banner bg-home vh-100 px-0" style={{backgroundImage:`url(${BannerImg})`}}>
    <Header/>
    <HomeBanner/>
    </section>

    <section className="py-md-5 py-4" />

    <section className="container-fluid">
      <div className="row justify-content-evenly gy-4">
        <div className="col-11 col-md-6">
          <div className="col-md-8 ps-lg-2 mx-auto">
          <div className="pb-2 pb-md-4 text-ss-primary fs-ss-18 fw-400" data-aos="fade-up">
            Invest in Dubai Real Estate
          </div>
          <div className="pb-md-3 text-white fs-ss-40 fw-500 lh-sm" data-aos="fade-up">
          Expert Assistance for All Your Real Estate Needs
          </div>
          </div>
        </div>
        <div className="col-11 col-md-6 col-lg-5">
        <div className="pb-4 text-white fs-ss-16 fw-300 col-md-10 pe-lg-5 lh-1-7em" data-aos="fade-up">
        We provide practical, reliable support tailored to your real estate needs. Our expert team provides you end to end services from property buying to after sales services with clarity and efficiency. We offer transparent advice and services to achieve your real estate goals in Dubai. 
          </div>
          <div className="pb-md-3 text-white fs-ss-18 fw-400" data-aos="fade-up">
            <Link to="/contact-us" className='text-decoration-none px-4 py-ss-2 text-white bg-ss-primary bg-hover-ss-primary rounded-5'>
            Contact for Details
            </Link>
          </div>
        </div>
      </div>
    </section>

     <section className="py-md-5 py-4" />
    {/* Mobile Space */}
    <section className="pt-4 pt-md-0" />

    <section className="container-fluid">
      <div className="row justify-content-center justify-content-lg-end text-md-center text-lg-start gy-4">
        <div className="col-11 col-md-8 col-lg-5 align-self-center">
        
        <div className="col-12 col-lg-10 mx-auto">

        <div className="text-uppercase fw-500 pb-2 pb-md-4">
          <div className="fs-ss-30 text-white text-shadow-parent pb-4" data-aos="fade-up">
          Our Real Estate Services 
          </div>
        </div>

          <div className="pb-md-4 text-white fs-ss-18 fw-300 col-lg-10">

            <div className="pb-4" data-aos="fade-up">
            <div className="text-ss-primary fs-ss-22 fw-600">
            Buying Properties 
            </div>
            <div className="lh-1-7em">
            We ease the property buying process by offering expert guidance every step of the way. 
            </div>
            </div>

            <div className="pb-4" data-aos="fade-up">
            <div className="text-ss-primary fs-ss-22 fw-600">
            Selling Properties
            </div>
            <div className="lh-1-7em">
            We make the selling process efficient and effective to achieve possible sales and maximize your property’s value. 
            </div>
            </div>

            <div className="pb-4" data-aos="fade-up">
            <div className="text-ss-primary fs-ss-22 fw-600">
            Investment Advisory 
            </div>
            <div className="lh-1-7em">
            Our advisory services provide clear, practical advice to help you make smart investment choices. 
            </div>
            </div>

          </div>
          </div>
        </div>
        <div className="col-11 col-md-8 col-lg-6 px-0" >
          <img src={Img1} className='w-100' data-aos="fade-up" alt="" />
        </div>
      </div>
    </section>

      <section className="py-md-5 py-4" />
          {/* Mobile Space */}
    <section className="pt-4 pt-md-0" />
      
    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}> 
    <section id='numbers' className="container-lg">
      <div className="row justify-content-center text-center px-md-5 gy-4">        
      <div className="pb-2 pb-md-4 text-white fs-ss-35 fw-500" data-aos="fade-up">
        Dubai Real Estate Transactions
        </div>
      <div className="col-md border-white border-end border-1">
          <div className="fs-ss-40 text-ss-primary fw-600">
          {counterOn && <CountUp start={0} end={17503} duration={4} delay={0}/>}
          </div>
          <div className="fs-ss-18 text-white">
          Total Transactions in September 
          </div>
        </div>

        <div className="col-md border-white border-end border-1">
          <div className="fs-ss-40 text-ss-primary fw-600">
          {counterOn && <CountUp start={0} end={43} duration={4} delay={0}/>}.57B
          </div>
          <div className="fs-ss-18 text-white">
          Property Sales in September 
          </div>
        </div>

        <div className="col-md">
          <div className="fs-ss-40 text-ss-primary fw-600">
          {counterOn && <CountUp start={0} end={14118} duration={4} delay={0}/>}
          </div>
          <div className="fs-ss-18 text-white">
          Units Sold in September 
          </div>
        </div>

        {/* <div className="col-md">
          <div className="fs-ss-40 text-ss-primary fw-600">
          {counterOn && <CountUp start={0} end={1473} duration={2} delay={0}/>}
          </div>
          <div className="fs-ss-18 text-white">
          Buildings Sold in July 
          </div>
        </div> */}

      </div>
    </section>
    </ScrollTrigger>
    
    {/* <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}> 
    <section id='numbers' className="container-lg">
      <div className="row justify-content-center text-center px-md-5 gy-4">        
      <div className="pb-2 pb-md-4 text-white fs-ss-35 fw-500" data-aos="fade-up">
        Dubai Real Estate Transactions
        </div>
        {records.map((list, index) => (
      <div className="col-md border-white border-start border-end border-1" key={index} style={{color:"black"}}>
          <div className="fs-ss-40 text-ss-primary fw-600">
          {counterOn && <CountUp start={0} end={list.id} duration={2} delay={0}/>}
          </div>
          <div className="fs-ss-18 text-white">
            {list.title}
          </div>
        </div>
      ))}
      </div>
    </section>
    </ScrollTrigger> */}


     <section className="py-md-5 py-4" />
    {/* Mobile Space */}
    <section className="pt-4 pt-md-0" />

    <section id='bg-middle-banner' className="container-fluid bg-middle-banner py-5" style={{backgroundImage:`url(${BgMiddle})`}}>
      <div className="row justify-content-evenly gy-4 py-md-5">
        <div className="col-11 col-md-9 mx-auto">
        <div className="pb-2 pb-md-4 text-white fs-ss-35 fw-600 col-lg-7" data-aos="fade-up">
        Your Safe Passage to Luxury Living 
          </div>
          <div className="pb-3 pb-md-4 text-white fs-ss-22 fw-300 col-lg-7 lh-1-7em" data-aos="fade-up">
          Find homes that combine quality and comfort. Our properties offer the best in design and amenities. From peaceful villas to modern city apartments, find a place that feels like home. 
          </div>
          <div className="text-white fs-ss-18 fw-400" data-aos="fade-up">
            <Link to="/contact-us" className='text-decoration-none px-4 py-ss-2 text-white bg-ss-primary bg-hover-ss-primary rounded-5'>
            Inquire Now
            </Link>
          </div>
        </div>
      </div>
    </section>

    <section className="py-md-5 py-4" />

    <section className="container-fluid">
    <div className="text-center" data-aos="fade-up">
      <div className="text-ss-primary fs-ss-18 fw-400">
      Trusted Developers
          </div>
          <div className="pb-md-4 pb-3 text-white fs-ss-35 fw-500 lh-sm">
          Partners in Property Excellence
          </div>
      </div>
      <div className="row justify-content-evenly align-items-center gy-0 px-4 px-md-5 mx-md-5" data-aos="fade-up">
        <div className="col-3 col-md-2 mx-md-1 px-md-5 hover-icons">
          <img src={Icon1} className='w-100' alt="" />
        </div>
        <div className="col-3 col-md-2 mx-md-1 px-md-5 hover-icons">
          <img src={Icon2} className='w-100' alt="" />
        </div>
        <div className="col-3 col-md-2 mx-md-1 px-md-5 hover-icons">
          <img src={Icon3} className='w-100' alt="" />
        </div>
        <div className="col-3 col-md-2 mx-md-1 px-md-5 hover-icons">
          <img src={Icon4} className='w-100' alt="" />
        </div>
        <div className="col-3 col-md-2 mx-md-1 px-md-5 hover-icons">
          <img src={Icon5} className='w-100' alt="" />
        </div>
        <div className="col-3 col-md-2 mx-md-1 px-md-5 hover-icons">
          <img src={Icon6} className='w-100' alt="" />
        </div>
        <div className="col-3 col-md-2 mx-md-1 px-md-5 hover-icons">
          <img src={Icon7} className='w-100' alt="" />
        </div>
        <div className="col-3 col-md-2 mx-md-1 px-md-5 hover-icons">
          <img src={Icon8} className='w-100' alt="" />
        </div>
        <div className="col-3 col-md-2 mx-md-1 px-md-5 hover-icons">
          <img src={Icon9} className='w-100' alt="" />
        </div>
        <div className="col-3 col-md-2 mx-md-1 px-md-5 hover-icons">
          <img src={Icon10} className='w-100' alt="" />
        </div>
        <div className="col-3 col-md-2 mx-md-1 px-md-5 hover-icons">
          <img src={Icon11} className='w-100' alt="" />
        </div>
        <div className="col-3 col-md-2 mx-md-1 px-md-5 hover-icons">
          <img src={Icon12} className='w-100' alt="" />
        </div>
        <div className="col-3 col-md-2 mx-md-1 px-md-5 hover-icons">
          <img src={Icon13} className='w-100' alt="" />
        </div>
        <div className="col-3 col-md-2 mx-md-1 px-md-5 hover-icons">
          <img src={Icon14} className='w-100' alt="" />
        </div>
        <div className="col-3 col-md-2 mx-md-1 px-md-5 hover-icons">
          <img src={Icon15} className='w-100' alt="" />
        </div>
        <div className="col-3 col-md-2 mx-md-1 px-md-5 hover-icons">
          <img src={Icon16} className='w-100' alt="" />
        </div>
        <div className="col-3 col-md-2 mx-md-1 px-md-5 hover-icons">
          <img src={Icon17} className='w-100' alt="" />
        </div>
        <div className="col-3 col-md-2 mx-md-1 px-md-5 hover-icons">
          <img src={Icon18} className='w-100' alt="" />
        </div>
        <div className="col-3 col-md-2 mx-md-1 px-md-5 hover-icons">
          <img src={Icon19} className='w-100' alt="" />
        </div>
        <div className="col-3 col-md-2 mx-md-1 px-md-5 hover-icons">
          <img src={Icon20} className='w-100' alt="" />
        </div>
        <div className="col-3 col-md-2 mx-md-1 px-md-5 hover-icons d-block d-md-none" />
      </div>
    </section>

    <section className="py-md-5 py-4 my-md-2" />
    {/* Mobile Space */}
    <section className="pt-md-0" />

    <section className="container-fluid">
      <div className="row justify-content-evenly gy-4 text-center">
        <div className="col-11 col-md-12">
        <div className="text-uppercase text-ss-primary fw-600 pb-2 pb-md-4" data-aos="fade-up">
          <div className="fs-ss-35 text-shadow-parent-2 bg-1 pt-2 pt-md-4">
            Why 800 Homes
          </div>
        </div>
          <div className=" text-white fs-ss-18 lh-1-7em fw-300 col-md-8 mx-auto" data-aos="fade-up">
          800 Homes stands out for its personalized real estate services in Dubai. With our detailed market expertise and tailored services, we promise a hassle-free and satisfying process. From finding the right property to guiding you through the buying process, we make real estate easy and accessible.
          </div>
        </div>
      </div>
    </section>

    <section className="py-md-5 py-4 my-md-2" />
    {/* Mobile Space */}
    <section className="pt-4 pt-md-0" />

    <section className="container-lg">
      <div className="text-center" data-aos="fade-up">
      <div className="text-ss-primary fs-ss-18 fw-400">
        800 Homes
          </div>
          <div className="pb-md-4 pb-3 text-white fs-ss-35 fw-500 lh-sm">
            Explore Properties
          </div>
      </div>
      <div id="testimonials" className="" data-aos="fade-up">  
          <HomeSliderListing/>                        
        <div className="pt-5 text-white fs-ss-18 fw-400 text-center" data-aos="fade-up">
          <Link to="/properties-for-sale" className='text-decoration-none px-4 py-ss-2 text-white bg-ss-primary bg-hover-ss-primary rounded-5'>
          View All
          </Link>
        </div> 
      </div>
    </section>

    <section className="py-md-5 py-4" />
    {/* Mobile Space */}
    <section className="pt-4 pt-md-0" />

    {/* <section className="container-lg">
      <div className="text-center" data-aos="fade-up">
      <div className="text-ss-primary fs-ss-18 fw-400">
        Testimonials
          </div>
          <div className="pb-md-5 pb-4 text-white fs-ss-35 fw-500 lh-sm">
          What Our Clients Say
          </div>
      </div>
      <Testimonials/>
    </section> */}

    {/* <section className="py-md-5 py-4 my-md-4" />

    <section className="pt-4 pt-md-0" /> */}

    {/* <section className="container-fluid px-md-5 px-lg-0">
        <div className="text-uppercase text-ss-primary text-center fw-600 pb-4 pb-md-5
        ">
          <div className="fs-ss-35 text-shadow-parent-3 bg-2 pt-2 pt-md-4" data-aos="fade-up">
            Latest News  
          </div>
        </div>
      <div className="d-lg-flex text-start">
        <div className="w-50 border border-white border-1 mb-4 mb-lg-0" data-aos="fade-up">
        <div className="">
          <img src={Img2} className='w-100' alt="" />
        </div>
        <div className="my-4">
          <div className="text-center mb-2">
        <Link to="/blogs" className="text-white text-decoration-none text-hover-ss-primary fs-ss-22 fw-400 lh-sm">
          Prime Investment Areas in Dubai
        </Link>
        </div>
        <div className="text-white fs-ss-16 fw-300 text-center pb-2">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Amet, beatae.
        </div>
        <div className="text-center">
        <Link to="/blogs" className='text-decoration-none text-ss-primary'> Read More <ChevronDoubleRight/> </Link>
        </div>
        </div>
        </div>

        <div className="px-2" />

        <div className="w-50">
          
        <div className="d-lg-flex border border-white border-1 mb-4 mb-md-3 text-center text-lg-start" data-aos="fade-up">
        <div className="w-50">
          <img src={Img3} className='w-100' alt="" />
        </div>
        <div className="w-50 my-4 my-lg-auto  px-3">
          
        <div className="mb-2">
        <Link to="/blogs" className="text-white text-decoration-none text-hover-ss-primary fs-ss-22 fw-400 lh-sm">
          Prime Investment Areas in Dubai
        </Link>
        </div>
        
        <div className="text-white fs-ss-16 fw-300 pb-2">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Amet, beatae.
        </div>
        <div className="fs-ss-16">
        <Link to="/blogs" className='text-decoration-none text-ss-primary'> Read More <ChevronDoubleRight/> </Link>
        </div>
        </div>
        </div>

        <div className="d-lg-flex border border-white border-1 mb-4 mb-md-0 text-center text-lg-start" data-aos="fade-up">
        <div className="w-50">
          <img src={Img4} className='w-100' alt="" />
        </div>
        <div className="w-50 my-4 my-lg-auto px-3">
        <div className="mb-2">
        <Link to="/blogs" className="text-white text-decoration-none text-hover-ss-primary fs-ss-22 fw-400 lh-sm">
          Prime Investment Areas in Dubai
        </Link>
        </div>
        <div className="text-white fs-ss-16 fw-300 pb-2">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Amet, beatae.
        </div>
        <div className="fs-ss-16">
        <Link to="/blogs" className='text-decoration-none text-ss-primary'> Read More <ChevronDoubleRight/> </Link>
        </div>
        </div>
        </div>
        </div>
        
      </div>
    </section>

     <section className="py-md-5 py-4" /> */}
    <Footer/>
    </section>
    </>
  );
}

export default Home;
