import React from "react"
import { EnvelopeFill, Facebook, Instagram, Linkedin, Tiktok, Twitter, Whatsapp, Youtube } from "react-bootstrap-icons";
import { EmailShareButton, FacebookShareButton, InstapaperShareButton, LinkedinShareButton, WhatsappShareButton } from "react-share";
const ShareSocialMediaIcons = (props) =>{

    return (
    <>
    <div className="text-center border-white border-1 border px-2 mt-4">
          <div className="fs-ss-18 text-white pb-4 pt-3">
            Share
          </div>
        <div className="fs-ss-22">

        <EmailShareButton url={props.url_Email}>
        <EnvelopeFill className='mx-2 mb-4 text-ss-primary'/> 
        </EmailShareButton>

        <WhatsappShareButton url={props.url_Whatsapp} >
        <Whatsapp className='mx-2 mb-4 text-ss-primary'/> 
        </WhatsappShareButton>

        <FacebookShareButton url={props.url_Facebook}>
        <Facebook className='mx-2 mb-4 text-ss-primary'/>
        </FacebookShareButton>

        <a href="https://www.instagram.com/800homesae/" target="_blank" className='text-decoration-none text-ss-primary text-hover-ss-primary' > <Instagram className='mx-2 mb-4'/> </a>


        <LinkedinShareButton url={props.url_Linkedin}>
        <Linkedin className='mx-2 mb-4 text-ss-primary'/> 
        </LinkedinShareButton>

          <a href="https://www.tiktok.com/@800homes" target="_blank" className='text-decoration-none text-ss-primary text-hover-ss-primary' > <Tiktok className='mx-2 mb-4'/> </a>
        </div>  
        </div>
    </>
    )
}

export default ShareSocialMediaIcons;