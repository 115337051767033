import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ProfileDesktop from '../components/faraaz/ProfileDesktop';
import ProfileMobile from '../components/faraaz/ProfileMobile';
import Aanil from '../assets/images/Aanil.webp';

const ProfileAanilMannwani = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>

    <Helmet>
    <meta name="robots" content="noindex"/>
    <meta charSet="utf-8" />
    <title> Aanil Mannwani - 800 Homes </title>
    </Helmet>

    <section id="website-responsive" className="d-none d-md-block">
      <ProfileDesktop
      Image={Aanil}
      Title="Aanil Mannwani"
      Designation="Real Estate Agent"
      PhoneNumberLink="+971564093862"
      PhoneNumber="+971 56 409 3862"
      WhatsappLink="971564093862"
      Whatsapp="+971 56 409 3862"
      Email="aanil@800homes.ae"
      />
    </section>

    <section id="website-responsive" className="d-block d-md-none">
      <ProfileMobile
      Image={Aanil}
      Title="Aanil Mannwani"
      Designation="Real Estate Agent"
      PhoneNumberLink="+971564093862"
      PhoneNumber="+971 56 409 3862"
      WhatsappLink="971564093862"
      Whatsapp="+971 56 409 3862"
      Email="aanil@800homes.ae" 
      />
    </section>

    </>
  );
}

export default ProfileAanilMannwani;
