import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Img1 from '../../assets/images/listing/Img1.webp';
import Img2 from '../../assets/images/listing/Img2.webp';
import Img3 from '../../assets/images/listing/Img3.webp';
import InquiryOfListing from "./individual/InquiryOfListing";
import ShareSocialMediaIcons from "./individual/ShareSocialMediaIcons";
import DetailsOfListing from "./individual/DetailsOfListing";

const IndividualPageOfListing = (props) => {
  
  const images = [
    {
      original: Img1,
      thumbnail: Img1,
    },
    {
      original: Img2,
      thumbnail: Img2,      
    },
    {
      original: Img3,
      thumbnail: Img3,      
    },
    
  ];
  return (
    <>
    <section id="website-responsive" className="container-fluid py-5 border-top border-1 border-white">
      <div className="row justify-content-evenly">
        <div className="col-11 col-md-7 px-0">
        <div className="">
          <div className="" data-aos="fade-up">
          <ImageGallery className="transition" items={images} />
          </div>
          <DetailsOfListing/>
      </div>
        </div>
        <div className="col-11 col-md-3">
          <div className="border-white border-1 border p-4 mb-4 sticky-top">
          <InquiryOfListing/>
          <ShareSocialMediaIcons/>
          </div>
        </div>        
      </div>
    </section>
    </>
  )
}

export default IndividualPageOfListing