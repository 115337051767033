import { useContext, createContext, useState, useEffect } from "react";
import axios from "axios";

const PropertiesContext = createContext({});

export const PropertiesProvider = ({ children }) => {
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchProperties = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/properties/all-properties?type=all`);
            if (response.data.status !== 200) {
                setLoading(false);
                throw new Error('Failed to fetch properties');
            }

            const properties = response.data.result.properties;
            setProperties(properties);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProperties();
    }, []);

    return (
        <PropertiesContext.Provider value={{ properties, loading }}>
            {children}
        </PropertiesContext.Provider>
    );
}

export function usePropertiesContext() {
    return useContext(PropertiesContext);
}