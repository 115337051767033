import React, {useState} from "react";
import { EnvelopeFill, GeoAlt, TelephoneFill, Whatsapp } from "react-bootstrap-icons";
import {Link} from 'react-router-dom';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Beds from '../../assets/images/listing/doublebed.png';
import Shower from '../../assets/images/listing/shower.png';
import SqFt from '../../assets/images/listing/ruller.png';
import { Modal } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'


const SingleListing = ({item, images}) => {
    const [show, setShow] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [comment, setComment] = useState(`I'm interested in [${item.name}]`);



    const handleSendForm = async(e) => {
        e.preventDefault()
        if(name === "" || email === "" || phone === "" || comment === ""){
            toast.error("Please fill all the fields")
            return
        }
        const btn = document.getElementById("submit-btn")
        btn.disabled = true
        //loading spinner
        btn.innerHTML = `<div class="spinner-border spinner-border-sm" role="status"> <span class="visually-hidden">Loading...</span> </div>`
        
        // if(!verified){
        //     toast.error("Please verify captcha")
        //     return
        // }
        const data = {
            name,
            email,
            phone,
            comment,
            property_url: item.url
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/leads`, data)
            
            if(res.data.status == 201){
                toast.success("Inquiry sent successfully")
                handleClearForm()
                handleClose()
            }else{
                toast.error("Something went wrong")
            }
        } catch (error) {
            toast.error("Something went wrong")
        } finally {
            btn.disabled = false
            btn.innerHTML = "Submit"
        }
    }

    const handleClearForm = () => {
        setName("")
        setEmail("")
        setPhone("")
        setComment(`I'm interested in [${item.name}]`)
    }

    const handleModal   = () => {
        setShow(true);
    }

    const handleClose = () => setShow(false);

  
    return (
        <>
            <div className="col-md-4 mb-5 d-flex flex-column">
                <div id="listing" className="text-grey border-1 border gy-4 border-white px-0 mx-md-3 flex-grow-1 d-flex flex-column" data-aos="fade-up">
                {/* Image */}
                <div id="listing2">
                    <ImageGallery items={
                        images.map((image) => {
                            return {
                                original: image.file,
                            }}
                        )
                    } />
                    </div>
                    <div className="ps-3 pt-3 pb-1 flex-grow-1">
                        <Link to={`/property/`+item.url} className="title-color text-grey align-text-line text-decoration-none fs-ss-22 fw-400">
                            {item.name}
                        </Link>
                        <div className="fs-ss-16 fw-400 pt-4 pb-1">
                            <GeoAlt className="mb-1"/> {item.location.city}
                        </div>
                        <div className="fs-ss-16 fw-300">
                            {item.type.name}
                        </div>

                        <div className="d-md-flex align-items-center fs-ss-14 fw-300 mt-2">
                            <div className="me-3">
                                <img src={Beds} className="listing-icons mb-2" alt="" />
                                <span className=""> Beds: </span>           
                                <span className=""> {item.bedrooms} </span> 
                            </div>
                            
                            <div className="me-3">
                                <img src={Shower} className="listing-icons mb-2" alt="" />
                                <span className=""> Baths: </span>           
                                <span className=""> {item.bathrooms} </span>                    
                            </div>
                            
                            <div className="">
                                <img src={SqFt} className="listing-icons me-1 mb-2" alt="" />
                                <span className=""> {Math.round(item.size)} sqft</span> 
                                <span className="ms-2"> / {Math.round(item.size * 0.092903)} sqm </span>
                            </div>          
                        </div>
                    </div>
                    <div className="text-uppercase fw-300 fs-ss-22 border-top border-1 border-white py-1 mx-3 mt-3">
                        AED <span className=""> {item.price?.value?.toLocaleString()} </span>
                    </div>

                    {/* Contact Us */}      
                    <div className="row justify-content-center fs-ss-18 text-center border-white border-1 border-top gx-0 align-items-center">
                        <a href="tel:+971585746637" className="col-4 text-decoration-none text-ss-primary text-hover-ss-primary" > <TelephoneFill/> </a>
                        {/* <a href="mailto:inquiry@800homes.ae" className="col-4 border-start border-end border-1 border-white py-1 text-decoration-none text-ss-primary text-hover-ss-primary" > <EnvelopeFill/> </a> */}
                        <button className="col-4 border-start border-end border-1 border-0 border-white py-1 text-decoration-none text-ss-primary text-hover-ss-primary bg-transparent" onClick={handleModal} > <EnvelopeFill/> </button>
                        <a target="_blank" href="https://api.whatsapp.com/send/?phone=971585746637&text&type=phone_number&app_absent=0" className="col-4 text-decoration-none text-ss-primary text-hover-ss-primary" > <Whatsapp/> </a>
                    </div>
                </div>
            </div>

            <Modal onHide={handleClose} className='modal fade inquiry-modal' size="md" show={show}>
                <div className='modal-header pb-0 border-0 justify-content-end'>
                    <button type="button" className="btn-close" onClick={handleClose}></button>
                </div>
                <Modal.Body className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15 py-5'>
                    <div className='text-center mb-13'>
                        <div className='fs-ss-22 fw-500 lh-sm pb-4'>
                            Inquiry - 800 Homes
                        </div>
                        {/* <IndividualListing /> */}
                        <form onSubmit={handleSendForm} id="" method="POST" action="#" className="">
                            <div className="form-group mb-3">
                                <input type="text" className="form-control fs-ss-16 fw-300 text-dark" id="name" name="name" placeholder="Name *" required value={name} onChange={
                                (e)=>setName(e.target.value)
                                }/>
                            </div>
                            <div className="form-group mb-3">
                                <input type="email" className="form-control fs-ss-16 fw-300" id="email" name="email" placeholder="Email *" required value={email} onChange={
                                (e)=>setEmail(e.target.value)
                                }/>
                            </div>
                            <div className="form-group mb-3">
                                {/* <input type="number" className="form-control fs-ss-16 fw-300" id="phone_number" name="phone_number" placeholder="Phone Number *" required value={phone} onChange={
                                (e)=>setPhone(e.target.value)
                                }/> */}
                                <PhoneInput
                                    placeholder="Phone Number *"
                                    id="phone"
                                    countryCallingCodeEditable={false}
                                    international
                                    defaultCountry='AE'
                                    value={phone}
                                    // countries={['US', 'CA']}
                                    className="form-control fs-ss-16"
                                    onChange={(e) => setPhone(e)}
                                    inputStyle={{
                                        border: 'none',
                                        outline: 'none',
                                        boxShadow: 'none',
                                    }}
                                />
                            </div>
                            <div className="form-group mb-4">
                                <textarea className="form-control fs-ss-16 fw-300" value={comment} onChange={(e)=>setComment(e.target.value)} id="message" name="message" rows="4" placeholder="Message"></textarea>
                            </div>
                            <div className="">
                                <button className='mt-2 text-center text-white fw-300 bg-ss-primary bg-hover-ss-primary rounded-0 border-0 text-ss-secondary fs-ss-16 px-3 py-ss-2 w-100' id="submit-btn" onClick={e=>handleSendForm}>
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SingleListing