import React, { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Img1 from '../../assets/images/listing/listing5/1.webp';
import Img2 from '../../assets/images/listing/listing5/2.webp';
import Img3 from '../../assets/images/listing/listing5/3.webp';
import Img4 from '../../assets/images/listing/listing5/4.webp';
import Img5 from '../../assets/images/listing/listing5/5.webp';
import Img6 from '../../assets/images/listing/listing5/6.webp';
import Img7 from '../../assets/images/listing/listing5/7.webp';
import Img8 from '../../assets/images/listing/listing5/8.webp';
import Img9 from '../../assets/images/listing/listing5/9.webp';
import Img10 from '../../assets/images/listing/listing5/10.webp';

import Header from "../../components/layout/Header";

import Footer from "../../components/layout/Footer";
import DetailsOfListing from "../../components/listing/individual/DetailsOfListing";
import InquiryOfListing from "../../components/listing/individual/InquiryOfListing";
import ShareSocialMediaIcons from "../../components/listing/individual/ShareSocialMediaIcons";
import { Link } from "react-router-dom";
import FeaturesAndAmenities from "../../components/listing/individual/FeaturesAndAmenities";
import ListingDetailsSection from "../../components/listing/individual/ListingDetailsSection";

const Listing5 = (props) => {
  
  const images = [
    {
      original: Img1,
      thumbnail: Img1,
    },
    {
      original: Img2,
      thumbnail: Img2,      
    },
    {
      original: Img3,
      thumbnail: Img3,      
    },
    {
      original: Img4,
      thumbnail: Img4,      
    },
    {
      original: Img5,
      thumbnail: Img5,      
    },
    {
      original: Img6,
      thumbnail: Img6,      
    },
    {
      original: Img7,
      thumbnail: Img7,      
    },
    {
      original: Img8,
      thumbnail: Img8,      
    },
    {
      original: Img9,
      thumbnail: Img9,      
    },
    {
      original: Img10,
      thumbnail: Img10,      
    },
    
  ];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Header/>
    <section id="website-responsive" className="container-fluid py-5 border-top border-1 border-white">
      <div className="row justify-content-evenly">
        <div className="col-11 col-md-7 px-0">
        <div className="">
          <div className="" data-aos="fade-up">
          <ImageGallery className="transition" items={images} />
          </div>
          <DetailsOfListing
          Title="APARTMENT FOR SALE IN AG 7EVEN, DUBAI RESIDENCE COMPLEX"
          Title2="Genuine ReSale | Q3 2025 | SMART HOME | HIGH ROI"
          Price="449,000"
          Location="Dubai"
          Category="Apartment"
          Beds="Studio"
          Baths="1"
          Area="371 sqft / 34 sqm"
          FeaturesAndAmenities={<>
            <FeaturesAndAmenities Title="Central A/C" />
            <FeaturesAndAmenities Title="Balcony" />
            <FeaturesAndAmenities Title="Shared Pool" />
            <FeaturesAndAmenities Title="Shared Spa" />
            <FeaturesAndAmenities Title="Security" />
            <FeaturesAndAmenities Title="Covered Parking" />
            <FeaturesAndAmenities Title="Built in Wardrobes" />
            <FeaturesAndAmenities Title="Kitchen Appliances" />
            <FeaturesAndAmenities Title="Lobby in Building" />
            <FeaturesAndAmenities Title="Children's Pool" />
            <FeaturesAndAmenities Title="Children's Play Area" />
            <FeaturesAndAmenities Title="Barbecue Area" />    
            </>}
          Description={<>
            Apartment for sale in AG 7even, Dubai Residence Complex
          <div className="py-2" />
          Modern Studio for Sale in AG 7even Tower, Dubai
          <div className="py-2" />
          Experience the perfect blend of luxury and convenience with this stunning studio apartment available for sale in the prestigious AG 7even Tower. Located in one of Dubai's most sought-after neighborhoods, DUBAI LAND RESIDENTIAL COMPLEX. This property offers an exceptional opportunity for both investors and homeowners looking for a stylish, modern living space in the heart of the city.
          <div className="py-2" />
          Property Features
          HANDOVER Q3, 2025 (JUST 1YEAR <br />
          PAYMENT PLA <br />
          STYLISH LIVING SPAC <br />
          MODERN FULLY FITTED KITCHEN <br />
          LUXURY SPACES <br />
          COMFORTABLE LIVING <br />
          PRIME LOCATION <br />
          WORLD CLASS AMENITIES <br />
          INVESTMENT POTENTIAL (HIGH ROI)
          <div className="py-2" />
          Situated in a prime location, AG 7even Tower offers residents easy access to some of Dubai's best dining, shopping, and entertainment options. The tower itself is a modern architectural marvel, featuring a sleek design and state-of-the-art facilities. The property is conveniently located near major transport links, making it easy to navigate the city and beyond. Whether you're commuting to work or exploring Dubai, you'll find everything within reach.
          <div className="py-2" />
          This studio apartment in AG 7even Tower is a rare find in Dubai's competitive real estate market. With its modern design, luxurious finishes, and prime location, it offers an unparalleled living experience. Don't miss the chance to own a piece of this prestigious development.
          <div className="py-2" />
          Don't miss the chance to make this stunning apartment yours. Contact us today to know more details and take the first step toward owning a piece of comfort at AG 7even.
          </>}
          ListingDetailsSection={<>
            <ListingDetailsSection Title1="Property Type" Title2="Apartment" />          
            <ListingDetailsSection Title1="Property Size" Title2="371 sqft / 34 sqm" />          
            <ListingDetailsSection Title1="Bedrooms" Title2="Studio" />          
            <ListingDetailsSection Title1="Bathrooms" Title2="1" />     
            </>}          
          />
      </div>
        </div>
        <div className="col-11 col-md-3">
          <div className="border-white border-1 border p-4 mb-4 sticky-top">
          <InquiryOfListing/>
          <ShareSocialMediaIcons
          url_Email="https://800homes.eacademe.org/apartment-for-sale-dubai-dubai-residence-complex-ag-7even-12246059"
          url_Whatsapp="https://800homes.eacademe.org/apartment-for-sale-dubai-dubai-residence-complex-ag-7even-12246059"
          url_Facebook="https://800homes.eacademe.org/apartment-for-sale-dubai-dubai-residence-complex-ag-7even-12246059"
          url_Linkedin="https://800homes.eacademe.org/apartment-for-sale-dubai-dubai-residence-complex-ag-7even-12246059"
          />
          </div>
        </div>        
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default Listing5