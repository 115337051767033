import React from 'react'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import Icon1 from '../assets/images/thankyou.webp'
import { Helmet } from 'react-helmet'
import { EnvelopeFill } from 'react-bootstrap-icons'

const ThankYou = () => {
    return (
        <>
        <Helmet>
          <title> Thank You | 800 Homes - Real Estate Brokerage </title>
        </Helmet>
        <Header/>
        <section id='website-responsive' className="container-fluid bg my-5 py-md-5">
            <div className="row justify-content-center">
                <div className="col-11 col-lg-7 bg-grey text-center text-white py-5 px-4">
                    <div className="pb-md-4">
                    <div className="fs-ss-68 text-ss-primary pb-4">
                    <img className='w-25 px-md-5' src={Icon1} alt="" />        
                    </div>
                    <div className='px-lg-5'>
                    <div className="fs-ss-30 fw-700">
                    Thank You!
                    </div>
                    <div className="fs-ss-16 fw-400 lh-para">
                    Thank you for your inquiry with <span className='fw-600'> 800 Homes - Real Estate Brokerage. </span> <br className="" /> We will contact you shortly! <br /> Please call our office at 
                    <a href="tel:80046637" className='text-decoration-none text-ss-primary fw-500' > 80046637 </a> |
                    <a href="tel:+971585746637" className='text-decoration-none text-ss-primary fw-500' > +971-58-574-6637 </a> 
                    if you have any questions.
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
        <Footer/>
        </>
    )
}

export default ThankYou