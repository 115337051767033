import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";

// Sources


const OneNavLink = (props) => {
    // useEffect(() => {
        const location = useLocation();
        const { pathname } = location;
        const splitLocation = pathname.split("/");
        const pageName = "/"+splitLocation[1];
        // console.log(pageName);
        
    // }, []);
    return (
        <>
        <div className="fw-400 fs-header-link px-md-2 px-lg-3 mx-lg-1 pb-4 pb-md-0">
            <Link to={props.Link} className={`text-decoration-none text-hover-ss-primary ${pageName === props.Link ? "text-ss-primary text-hover-ss-primary" : "text-white"}`}>{props.NavItem}</Link>     
        </div>
        </>
    );
}

export default OneNavLink;
