import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Img1 from '../assets/images/DrMinhas.webp';
import Img2 from '../assets/images/Faraz.webp';
import BannerImg from '../assets/images/banners/Banner1.webp';
import Leader from '../components/leadership/Leader';
import LeaderReverse from '../components/leadership/LeaderReverse';


const Leadership = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
            <>
        <Helmet>
            <meta charSet="utf-8" />
            <title> </title>
            <meta name="description" content="" />
            <link rel="canonical" href="https://800homes.ae/leadership"/>
        </Helmet>
    <Header/>
    <section id='website-responsive' className="container-fluid px-0">
    <section id='website-responsive' className="container-fluid common-banner shadow-800homes" style={{backgroundImage:`url(${BannerImg})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 col-lg-10">
          <h1 className="lh-sm fw-600 banner-title text-uppercase text-white text-center" data-aos="fade-up">
          Our Leadership
          </h1>
          <div>
          </div>
        </div>
      </div>
    </section>

    <section className="py-md-5 py-4" />

    <section className="container-fluid">
      <div className="row justify-content-evenly gy-4 text-center">
        <div className="col-11 col-md-12">
        <div className="fs-ss-30 fw-600 text-white pb-3" data-aos="fade-up">
          Meet Our Leaders
        </div>
        <div className=" text-white fs-ss-18 lh-1-7em fw-300 col-md-8 mx-auto" data-aos="fade-up">
          Our leadership team brings extensive experience and deep real estate industry knowledge. With a clear understanding of Dubai’s property market, our leaders are dedicated to supporting our clients throughout their real estate experience.  
        </div>
        </div>
      </div>
    </section>

     <section className="py-md-5 py-4" />

     <Leader 
     Img={Img2}
     Title="Faraaz Siddiqui"
     Para="Mr. Faraaz is a skilled property consultant who excels in navigating Dubai’s real estate market. He leads 800 Homes with a strategic vision. His commitment to excellence and extensive market knowledge ensures clients receive outstanding service and guidance throughout their real estate journey."
     />

     <LeaderReverse
     Img={Img1}
     Title="Dr. Minhaz"
     Para="Dr. Minhaz brings experience and strategic insight to our real estate operations. His profound understanding of Dubai’s property market and commitment to excellence have been key in establishing 800 Homes as a leader in the industry. His visionary approach and leadership drive the company forward, achieving exceptional outcomes for our clients.  "
     />     
    <Footer/>
    </section>
    </>
  );
}

export default Leadership;
