import React from 'react';
import OneNavLink from './OneNavLink';
import OneNavLinkDropdown from './OneNavLinkDropdown';
import { Link, useLocation } from 'react-router-dom';

// Sources


const NavBar = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const pageName = "/"+splitLocation[1];
  return (
    <>  

    {/* <OneNavLinkDropdown 
    NavItem="Why 800 Homes"
    Link="/why-800-homes"
    DropdownMenu={<>
    <OneNavLink 
    NavItem="Leadership"
    Link="/leadership"
    />
    </>}
    /> */}

<OneNavLinkDropdown 
    NavItem="Why 800 Homes"
    Link="/why-800-homes"
    DropdownMenu={
            <>
        <div className="fw-400 fs-header-link px-md-3 mx-md-1 pb-4 pb-md-0">
            <Link to="/leadership" className={`text-decoration-none text-ss-primary text-hover-ss-primary ${pageName === "/leadership"  ? "text-ss-primary text-hover-ss-primary" : "text-dark"}`}>
            Leadership
            </Link>     
        </div>
    </>}
    />

        <OneNavLink 
    NavItem="Why Dubai"
    Link="/why-dubai"
    />
    
    <OneNavLink 
    NavItem="Buy Property"
    Link="/properties-for-sale"
    />    
            <OneNavLink 
    NavItem="Sell Property"
    Link="/sell-property"
    />

        <OneNavLink 
    NavItem="Careers"
    Link="/careers"
    />
            {/* <OneNavLink 
    NavItem="Latest News"
    Link="/blogs"
    /> */}
        <OneNavLink 
    NavItem="Contact Us"
    Link="/contact-us"
    />
    </>
  );
}

export default NavBar;
