import React from 'react';
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom";

const OneNavLinkDropdown = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const pageName = "/"+splitLocation[1];
  return (
    <>
    <div className="fw-400 fs-header-link px-md-2 px-lg-3 mx-lg-1 pb-4 pb-md-0 right-menu">
      <Link to={props.Link} className={`text-decoration-none text-hover-ss-primary ${pageName === props.Link ? "text-ss-primary text-hover-ss-primary" : "text-white"}`}>{props.NavItem}</Link>   
      <ul className="dropdown-menu hover1 fs-ss-links w-content">
        {props.DropdownMenu}
      </ul>  
    </div>
    </>
  )
}

export default OneNavLinkDropdown