import React from "react"
import { EnvelopeFill, GeoAlt, GeoAltFill, TelephoneFill, Whatsapp } from "react-bootstrap-icons";
import {Link} from 'react-router-dom';
import Beds from '../../../assets/images/listing/doublebed.png';
import Shower from '../../../assets/images/listing/shower.png';
import SqFt from '../../../assets/images/listing/ruller.png';
import FeaturesAndAmenities from "./FeaturesAndAmenities";

const ListingDetailsSection = (props) =>{

    return (
    <>
        <div className="col-11 col-md-5 border-bottom border-1 border-white mb-2 fw-300">
            <div className="d-flex">
            <div className="">
                {props.Title1}
            </div>
            <div className="ms-auto">
                {props.Title2}
            </div>
            </div>
        </div>
    </>
    )
}

export default ListingDetailsSection