import React, {useState, useRef} from "react"
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from 'emailjs-com'
import { toast } from "react-toastify";
import axios from "axios";
import PhoneInputWithCountrySelect from "react-phone-number-input";

const InquiryOfListing = ({propertyName, propertyUrl}) =>{
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [comment, setComment] = useState(`I'm interested in [${propertyName}]`);

    const refform = useRef();


    const handleSendForm = async(e) => {
        e.preventDefault()
        
        if(name === "" || email === "" || phone === "" || comment === ""){
            toast.error("Please fill all the fields")
            return
        }
        const btn = document.getElementById("submit-btn")
        btn.disabled = true
        //loading spinner
        btn.innerHTML = `<div class="spinner-border spinner-border-sm" role="status"> <span class="visually-hidden">Loading...</span> </div>`
        // if(!verified){
        //     toast.error("Please verify captcha")
        //     return
        // }
        const data = {
            name,
            email,
            phone,
            comment,
            property_url: propertyUrl
        }

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/leads`, data)
            
            if(res.data.status == 201){
                toast.success("Inquiry sent successfully")
                handleClearForm()
            }else{
                toast.error("Something went wrong")
            }
        } catch (error) {
            toast.error("Something went wrong")
        } finally {
            btn.disabled = false
            btn.innerHTML = "Submit"
        }
    }

    const handleClearForm = () => {
        setName("")
        setEmail("")
        setPhone("")
        setComment(`I'm interested in [${propertyName}]`)
    }


    return (
    <>
    <section className="container-fluid bg-grey-2 rounded-0 px-0">
        <div className="row justify-content-center align-items-center">
            <div className="col-12">
                <div className='fs-ss-22 fw-500 lh-sm text-white pb-4 text-center'>
                  Inquiry - 800 Homes
                </div>
                <div className="">
                <form ref={refform} onSubmit={handleSendForm} id="contactForm" method="POST" action="#" className="">
                    <div className="form-group mb-3">
                        <input type="text" className="form-control text-grey fs-ss-16 fw-300" id="name" name="name" placeholder="Name *" required value={name} onChange={
                        (e)=>setName(e.target.value)
                        }/>
                    </div>
                    <div className="form-group mb-3">
                        <input type="email" className="form-control text-grey fs-ss-16 fw-300" id="email" name="email" placeholder="Email *" required value={email} onChange={
                        (e)=>setEmail(e.target.value)
                        }/>
                    </div>
                    <div className="form-group mb-3">
                        {/* <input type="number" className="form-control text-grey fs-ss-16 fw-300" id="phone_number" name="phone_number" placeholder="Phone Number *" required value={phone} onChange={
                        (e)=>setPhone(e.target.value)
                        }/> */}
                        <PhoneInputWithCountrySelect
                            placeholder="Phone Number *"
                            id="phone"
                            countryCallingCodeEditable={false}
                            international
                            defaultCountry='AE'
                            value={phone}
                            // countries={['US', 'CA']}
                            className="form-control fs-ss-16"
                            onChange={(e) => setPhone(e)}
                            inputStyle={{
                                border: 'none',
                                outline: 'none',
                                boxShadow: 'none',
                            }}
                        />
                    </div>
                    <div className="form-group mb-4">
                        <textarea className="form-control text-grey fs-ss-16 fw-300" value={comment} onChange={(e)=>setComment(e.target.value)} id="message" name="message" rows="4" placeholder="Message"></textarea>
                    </div>
                    <div className="">
                        <button className='mt-2 text-center text-white fw-300 bg-ss-primary bg-hover-ss-primary rounded-0 border-0 text-ss-secondary fs-ss-16 px-3 py-ss-2 w-100' id="submit-btn" onClick={e=>handleSendForm}>
                            Submit
                        </button>
                    </div>
                </form>
                <div className="d-flex">
                        <div className="w-50 pe-1">
                        <a href="tel:+971585746637" className='d-block text-decoration-none mt-2 text-center text-white fw-300 border-ss-primary bg-hover-ss-primary rounded-0 fs-ss-16 px-3 py-ss-2 w-100'>
                        Call
                    </a>
                        </div>
                        <div className="w-50 ps-1">
                        <a target="_blank" href="https://api.whatsapp.com/send/?phone=971585746637&text&type=phone_number&app_absent=0" className='d-block text-decoration-none mt-2 text-center text-white fw-300 border-ss-primary bg-hover-ss-primary rounded-0 fs-ss-16 px-3 py-ss-2 w-100'>
                        WhatsApp
                    </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
    )
}

export default InquiryOfListing;