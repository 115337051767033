import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/layout/Footer';
import BannerImg from '../assets/images/banners/Banner1.webp';
import Header from '../components/layout/Header';
import CareersForm from '../components/CareersForm';

const Careers = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
            <>
        <Helmet>
        <meta charSet="utf-8" />
        <title> Work with 800 Homes | Careers in Real Estate Brokerage in Dubai </title>
        <meta name="description" content="Build your real estate career with 800 Homes in Dubai. We’re seeking talented professionals to join our brokerage team and thrive in the fast-paced Dubai property market." />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Work with 800 Homes | Careers in Real Estate Brokerage in Dubai" />
        <meta property="og:description" content="Build your real estate career with 800 Homes in Dubai. We’re seeking talented professionals to join our brokerage team and thrive in the fast-paced Dubai property market." />
        <meta property="og:url" content="https://800homes.ae/" />
        <meta property="og:site_name" content="800homes" />
        <meta property="og:image" content="https://800homes.ae/og.webp" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://800homes.ae/og.webp" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Build your real estate career with 800 Homes in Dubai. We’re seeking talented professionals to join our brokerage team and thrive in the fast-paced Dubai property market." />
        <meta name="twitter:title" content="Work with 800 Homes | Careers in Real Estate Brokerage in Dubai" />
        <meta name="twitter:image" content="https://800homes.ae/og.webp"/>
        <link rel="canonical" href="https://800homes.ae/careers"/>
        </Helmet>
    <Header/>

    <section id='website-responsive' className="container-fluid common-banner shadow-800homes" style={{backgroundImage:`url(${BannerImg})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 col-lg-10">
          <h1 className="lh-sm fw-600 banner-title text-uppercase text-white text-center" data-aos="fade-up">
          Careers
          </h1>
          <div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-md-5'/>

    <section className="container fluid">
      <div className="row justify-content-center">
        <div className="col-11 col-md-6">
          <CareersForm/>
        </div>
      </div>
    </section>
    <section className='pt-5 mt-md-5'/>

    <Footer/>
    </>
  );
}

export default Careers;
