import React,{useEffect,useState} from "react"
import {Link} from "react-router-dom"
import axios from 'axios'
import { Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { ChevronDoubleRight, ChevronRight } from "react-bootstrap-icons"
import BannerImg from '../assets/images/banners/Banner1.webp';
import BlogsImg from '../assets/images/blogs/blog.jpg';
import Header from "../components/layout/Header"
import Footer from "../components/layout/Footer"

const Blogs = () =>{
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    const [blogs, setBlogs] = React.useState([])
    
    const [contentLoading, setContentLoading] = React.useState(true)
    const [isReadMoreshown, setReadMoreShown] = useState(false)
    const toggleBtn = () => {
        setReadMoreShown(prevState => !prevState)
    }
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/blogs/website`).then(res => {
            if(res.data.blogs.length > 0){
                setBlogs(res.data.blogs)
            }else{
                setBlogs([])
            }
        }).catch(err => {
            console.log(err)
        }).finally (() => {
            setContentLoading(false)
        })
    },[])

    return (
            <>
        <Helmet>
        <meta charSet="utf-8" />
        <title> Dubai Real Estate News - Market Trends & Updates </title>
        <meta name="description" content="Stay updated with the latest news on Dubai real estate with 800 Homes. Get insights on property trends, market updates, and expert analysis from our team of brokers." />

        <meta property="og:type" content="website" />
        <meta property="og:title" content="Dubai Real Estate News - Market Trends & Updates" />
        <meta property="og:description" content="Stay updated with the latest news on Dubai real estate with 800 Homes. Get insights on property trends, market updates, and expert analysis from our team of brokers." />
        <meta property="og:url" content="https://800homes.ae/" />
        <meta property="og:site_name" content="800homes" />
        <meta property="og:image" content="https://800homes.ae/og.webp" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://800homes.ae/og.webp" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Stay updated with the latest news on Dubai real estate with 800 Homes. Get insights on property trends, market updates, and expert analysis from our team of brokers." />
        <meta name="twitter:title" content="Dubai Real Estate News - Market Trends & Updates" />
        <meta name="twitter:image" content="https://800homes.ae/og.webp"/>
        <link rel="canonical" href="https://800homes.ae/blogs"/>
        </Helmet>
    <Helmet>
      <title>
      Latest News | 800 Homes - Real Estate Brokerage 
      </title>
    </Helmet>
    <Header/>

    <section id='website-responsive' className="container-fluid common-banner shadow-800homes" style={{backgroundImage:`url(${BannerImg})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 col-lg-10">
          <h1 className="lh-sm fw-600 banner-title text-uppercase text-white text-center" data-aos="fade-up">
            Latest News
          </h1>
          <div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-md-5'/>

    <section id="website-responsive" className="container-fluid py-4 font-pri">
        <div className="row justify-content-center gap-5">
        {contentLoading ?
                    <div className='col-md-12 col-sm-12 text-center'>
                        <Spinner animation="border" variant="white" />
                    </div>
                :
                <>
                {blogs.length > 0 ?
                    <>
                        {blogs.map((blog, index) => {
                            return (
                                <>
                                                                <div className="w-blogs hover-blogs border-white border-1 border-bottom border-1 border border-white p-0" data-aos="fade-up" >
                                    
                                    <div className="image-box">
                                        <Link to={`/blog/${blog?.slug}`} className="">
                                            <img className='w-100' src={blog?.featured_image1} alt={blog?.title}/>
                                        </Link>
                                    </div>
                                    <div className='py-4 px-3'>
                                        <div className="fs-ss-20 pt-2">
                                            <Link to={`/blog/${blog?.slug}`} className="text-white text-decoration-none align-text-line2">
                                                <div style={{fontWeight:'500'}}>
                                                {blog?.title}      
                                                {/* Lorem ipsum dolor sit amet consectetur adipisicing elit                   */}
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="fs-ss-15 fw-300 pt-2 text-grey lh-1-7em align-text-line3">
                                        {blog?.description}
                                            {/* Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint maiores facilis labore! Assumenda vero voluptas atque et delectus recusandae omnis. */}
                                        </div>
                                        <div className='fs-ss-14 pt-3'>
                                            <Link to={`/blog/${blog?.slug}`} className="text-decoration-none fw-500 text-ss-primary">
                                            Read More <ChevronDoubleRight className=""/>
                                            </Link>
                                        </div>
                                    </div>
                                </div> 
                                </>
                            )
                        })}
                    </>
                    :
                    <div className='col-md-12 col-sm-12 text-center'>
                        <div className='fs-zk-22 text-dark text-montserrat mb-2' style={{fontWeight:"600"}}>No Blogs Found</div>
                    </div>
                    }
                </>
                }
        </div>
    </section>

    <section className='pt-5 mt-md-5'/>

    <Footer/>
    </>
    )
}

export default Blogs;