import React, {useEffect} from 'react'
import Header from '../components/layout/Header'
import {Link} from 'react-router-dom'
import Footer from '../components/layout/Footer'

const Error404 = () => {
    useEffect(() => {
        window.document.title = "Page Not Found (404) | 800 Homes - Real Estate Brokerage"
    }, [])
    return (
        <>
        <div className="px-0 bg"> 
        <Header/>
            <div className='' id="website-responsive">
                {/* <BannerCategory /> */}
                <section className='container'>
                    <div className='container-ss my-5 text-center text-white bg-grey py-5'>
                        <h4 className='fw-bolder fs-2hx text-gray-900 mb-4'>This page doesn't seem to exist.</h4>
                        <div className='fs-6 text-gray-500 mb-7'>It looks like the link pointing here was faulty. Visit home page!</div>
                        <div className='mt-5'>
                            <Link to='/' className='btn btn-sm btn-ss-primary py-ss-2 px-3'>
                                Return Home
                            </Link>
                        </div>
                    </div>
                </section>
            </div>
        <Footer/>
        </div>
        </>
    )
}

export default Error404