import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import { Helmet } from 'react-helmet';
import BannerImg from '../assets/images/banners/Banner1.webp';
import { EnvelopeFill, Facebook, GeoAltFill, Instagram, Linkedin, PhoneFill, TelephoneFill, Tiktok, Whatsapp } from 'react-bootstrap-icons';
import InquiryForm from '../components/InquiryForm';

const ContactUs = () => {
  return (
  <>
  <Helmet>
  <meta charSet="utf-8" />
        <title> Contact us for residential or commercical property bokerage </title>
        <meta name="description" content="Need assistance with buying or selling property in Dubai? Contact 800 Homes for expert real estate advice and professional services tailored to your needs." />
        
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Contact us for residential or commercical property bokerage" />
        <meta property="og:description" content="Need assistance with buying or selling property in Dubai? Contact 800 Homes for expert real estate advice and professional services tailored to your needs." />
        <meta property="og:url" content="https://800homes.eacademe.org/" />
        <meta property="og:site_name" content="800homes" />
        <meta property="og:image" content="https://800homes.eacademe.org/og.webp" />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        <meta property="og:image:secure_url" content="https://800homes.eacademe.org/og.webp" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Need assistance with buying or selling property in Dubai? Contact 800 Homes for expert real estate advice and professional services tailored to your needs." />
        <meta name="twitter:title" content="Contact us for residential or commercical property bokerage" />
        <meta name="twitter:image" content="https://800homes.eacademe.org/og.webp"/>
        <link rel="canonical" href="https://800homes.eacademe.org/contact-us"/>
  </Helmet>
  <Header/>
  <section id='website-responsive' className="container-fluid common-banner shadow-800homes" style={{backgroundImage:`url(${BannerImg})`}}>
      <div className="row justify-content-center align-items-center">
        <div className="col-11 col-lg-10">
          <h1 className="lh-sm fw-600 banner-title text-uppercase text-white text-center" data-aos="fade-up">
          Contact Us
          </h1>
          <div>
          </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-md-5'/>

<section id='website-responsive' className="container-lg text-ss-secondary">
  <div className="row justify-content-evenly align-items-center gy-4">
    <div className="col-11 col-md-5 d-none d-md-block" data-aos="fade-up">
    <InquiryForm/>
    </div>
    <div className="col-11 col-md-5" >
    <div className='fs-ss-30 fw-500 lh-sm text-white' data-aos="fade-up">
    Get In Touch
    </div>
    <div className="fs-ss-16 text-grey fw-300 py-3 lh-1-7em" data-aos="fade-up">
    Contact us via phone, email, or in person, and let's discuss how we can help you find the perfect home.
    </div>

    <div className="pt-2 pt-md-3" data-aos="fade-up">
          <div className="pb-3"> 
           <a className='text-decoration-none text-grey text-hover-ss-primary fw-300' href="tel:80046637" target='_blank'> <TelephoneFill className='text-ss-primary me-2'/> 80046637 </a> 
           </div>
           <div className="pb-3"> 
           <a className='text-decoration-none text-grey text-hover-ss-primary fw-300' href="tel:+971585746637" target='_blank'> <PhoneFill className='text-ss-primary me-2'/> +971-58-574-6637 </a> 
           </div>
           <div className="pb-3"> 
           <a className='text-decoration-none text-grey text-hover-ss-primary fw-300' href="https://api.whatsapp.com/send/?phone=971585746637&text&type=phone_number&app_absent=0" target='_blank'> <Whatsapp className='text-ss-primary me-2'/> +971-58-574-6637 </a> 
           </div>
           <div className="pb-3"> 
           <a className='text-decoration-none text-grey text-hover-ss-primary fw-300' href="mailto:inquiry@800homes.ae" target='_blank'> <EnvelopeFill className='text-ss-primary me-2'/> inquiry@800homes.ae </a> 
           </div>
           <span className="pb-3 text-grey text-hover-ss-primary fw-300"> 
               <GeoAltFill className='text-ss-primary me-2'/>1104-72, Xavier Business Center, <br className=""/>
               <span className='ms-4' /> Ibn Battuta Gate Offices, Jebel Ali
               <br className=""/>
               <span className='ms-4' /> Dubai - United Arab Emirates
               </span>
                </div>
        </div>
  </div>
</section>
<section className='pt-5 mt-md-5 pb-md-4'/>
    
    <section id='website-responsive' className="container-lg text-ss-secondary">
      <div className="row justify-content-center text-center">
        <div className="col-11 col-lg-11">
          <div className=''>
            <div className='fs-ss-30 fw-600 fw-600 lh-sm text-white' data-aos="fade-up">
            Connect with us on <span className='text-ss-primary'> Social Media  </span>
              </div>
              <div className='pt-4'/>
              <div className="text-center" data-aos="fade-up">
              <div className="text-ss-secondary fs-ss-30 text-white">
              <a href="https://www.facebook.com/800Homes" target="_blank" className='text-decoration-none text-grey text-hover-ss-primary' > <Facebook className='me-4'/> </a>
              <a href="https://www.instagram.com/800homesae/" target="_blank" className='text-decoration-none text-grey text-hover-ss-primary' > <Instagram className='me-4'/> </a>
              <a href="https://www.tiktok.com/@800homes" target="_blank" className='text-decoration-none text-grey text-hover-ss-primary' > <Tiktok className='me-4'/> </a>
              <a href="https://www.linkedin.com/company/800-homes-real-estate-brokerage-firm/" target="_blank" className='text-decoration-none text-grey text-hover-ss-primary' > <Linkedin className='me-4'/> </a>
              </div>         
              </div>
            </div>
        </div>
      </div>
    </section>

    <section className='pt-5 mt-md-5'/>
     
    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462561.6574537445!2d55.22748795!3d25.076022449999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1727182591517!5m2!1sen!2s" className="w-100" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/> */}
  <Footer/>
  </>
  );
}

export default ContactUs;
