import React, { useEffect } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Img1 from '../../assets/images/listing/listing8/1.webp';
import Img2 from '../../assets/images/listing/listing8/2.webp';
import Img3 from '../../assets/images/listing/listing8/3.webp';
import Img4 from '../../assets/images/listing/listing8/4.webp';
import Img5 from '../../assets/images/listing/listing8/5.webp';
import Img6 from '../../assets/images/listing/listing8/6.webp';
import Img7 from '../../assets/images/listing/listing8/7.webp';
import Img8 from '../../assets/images/listing/listing8/8.webp';
import Img9 from '../../assets/images/listing/listing8/9.webp';
import Img10 from '../../assets/images/listing/listing8/10.webp';
import Img11 from '../../assets/images/listing/listing8/11.webp';
import Img12 from '../../assets/images/listing/listing8/12.webp';
import Img13 from '../../assets/images/listing/listing8/13.webp';
import Img14 from '../../assets/images/listing/listing8/14.webp';
import Img15 from '../../assets/images/listing/listing8/15.webp';
import Img16 from '../../assets/images/listing/listing8/16.webp';
import Img17 from '../../assets/images/listing/listing8/17.webp';
import Img18 from '../../assets/images/listing/listing8/18.webp';
import Img19 from '../../assets/images/listing/listing8/19.webp';
import Img20 from '../../assets/images/listing/listing8/20.webp';
import Img21 from '../../assets/images/listing/listing8/21.webp';
import Img22 from '../../assets/images/listing/listing8/22.webp';
import Img23 from '../../assets/images/listing/listing8/23.webp';
import Img24 from '../../assets/images/listing/listing8/24.webp';
import Img25 from '../../assets/images/listing/listing8/25.webp';
import Img26 from '../../assets/images/listing/listing8/26.webp';
import Img27 from '../../assets/images/listing/listing8/27.webp';
import Img28 from '../../assets/images/listing/listing8/28.webp';
import Img29 from '../../assets/images/listing/listing8/29.webp';
import Img30 from '../../assets/images/listing/listing8/30.webp';
import Img31 from '../../assets/images/listing/listing8/31.webp';
import Img32 from '../../assets/images/listing/listing8/32.webp';
import Img33 from '../../assets/images/listing/listing8/33.webp';
import Img34 from '../../assets/images/listing/listing8/34.webp';
import Img35 from '../../assets/images/listing/listing8/35.webp';
import Img36 from '../../assets/images/listing/listing8/36.webp';
import Img37 from '../../assets/images/listing/listing8/37.webp';
import Img38 from '../../assets/images/listing/listing8/38.webp';
import Img39 from '../../assets/images/listing/listing8/39.webp';
import Img40 from '../../assets/images/listing/listing8/40.webp';
import Img41 from '../../assets/images/listing/listing8/41.webp';
import Img42 from '../../assets/images/listing/listing8/42.webp';
import Img43 from '../../assets/images/listing/listing8/43.webp';
import Img44 from '../../assets/images/listing/listing8/44.webp';
import Img45 from '../../assets/images/listing/listing8/45.webp';
import Img46 from '../../assets/images/listing/listing8/46.webp';
import Img47 from '../../assets/images/listing/listing8/47.webp';
import Img48 from '../../assets/images/listing/listing8/48.webp';
import Img49 from '../../assets/images/listing/listing8/49.webp';
import Img50 from '../../assets/images/listing/listing8/50.webp';

import Header from "../../components/layout/Header";

import Footer from "../../components/layout/Footer";
import DetailsOfListing from "../../components/listing/individual/DetailsOfListing";
import InquiryOfListing from "../../components/listing/individual/InquiryOfListing";
import ShareSocialMediaIcons from "../../components/listing/individual/ShareSocialMediaIcons";
import { Link } from "react-router-dom";
import FeaturesAndAmenities from "../../components/listing/individual/FeaturesAndAmenities";
import ListingDetailsSection from "../../components/listing/individual/ListingDetailsSection";

const Listing8 = (props) => {
  
  const images = [
    {
      original: Img1,
      thumbnail: Img1,
    },
    {
      original: Img2,
      thumbnail: Img2,      
    },
    {
      original: Img3,
      thumbnail: Img3,      
    },
    {
      original: Img4,
      thumbnail: Img4,      
    },
    {
      original: Img5,
      thumbnail: Img5,      
    },
    {
      original: Img6,
      thumbnail: Img6,      
    },
    {
      original: Img7,
      thumbnail: Img7,      
    },
    {
      original: Img8,
      thumbnail: Img8,      
    },
    {
      original: Img9,
      thumbnail: Img9,      
    },
    {
      original: Img10,
      thumbnail: Img10,      
    },
    {
      original: Img11,
      thumbnail: Img11,      
    },
    {
      original: Img12,
      thumbnail: Img12,      
    },
    {
      original: Img13,
      thumbnail: Img13,      
    },
    {
      original: Img14,
      thumbnail: Img14,      
    },
    {
      original: Img15,
      thumbnail: Img15,      
    },
    {
      original: Img16,
      thumbnail: Img16,      
    },
    {
      original: Img17,
      thumbnail: Img17,      
    },
    {
      original: Img18,
      thumbnail: Img18,      
    },
    {
      original: Img19,
      thumbnail: Img19,      
    },
    {
      original: Img20,
      thumbnail: Img20,      
    },
    {
      original: Img21,
      thumbnail: Img21,      
    },
    {
      original: Img22,
      thumbnail: Img22,      
    },
    {
      original: Img23,
      thumbnail: Img23,      
    },
    {
      original: Img24,
      thumbnail: Img24,      
    },
    {
      original: Img25,
      thumbnail: Img25,      
    },
    {
      original: Img26,
      thumbnail: Img26,      
    },
    {
      original: Img27,
      thumbnail: Img27,      
    },
    {
      original: Img28,
      thumbnail: Img28,      
    },
    {
      original: Img29,
      thumbnail: Img29,      
    },
    {
      original: Img30,
      thumbnail: Img30,      
    },
    {
      original: Img31,
      thumbnail: Img31,      
    },
    {
      original: Img32,
      thumbnail: Img32,      
    },
    {
      original: Img33,
      thumbnail: Img33,      
    },
    {
      original: Img34,
      thumbnail: Img34,      
    },
    {
      original: Img35,
      thumbnail: Img35,      
    }, 
    {
      original: Img36,
      thumbnail: Img36,      
    }, 
    {
      original: Img37,
      thumbnail: Img37,      
    }, 
    {
      original: Img38,
      thumbnail: Img38,      
    }, 
    {
      original: Img39,
      thumbnail: Img39,      
    }, 
    {
      original: Img40,
      thumbnail: Img40,      
    }, 
    {
      original: Img41,
      thumbnail: Img41,      
    }, 
    {
      original: Img42,
      thumbnail: Img42,      
    }, 
    {
      original: Img43,
      thumbnail: Img43,      
    }, 
    {
      original: Img44,
      thumbnail: Img44,      
    }, 
    {
      original: Img45,
      thumbnail: Img45,      
    }, 
    {
      original: Img46,
      thumbnail: Img46,      
    }, 
    {
      original: Img47,
      thumbnail: Img47,      
    }, 
    {
      original: Img48,
      thumbnail: Img48,      
    }, 
    {
      original: Img49,
      thumbnail: Img49,      
    }, 
    {
      original: Img50,
      thumbnail: Img50,      
    }, 
  ];

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Header/>
    <section id="website-responsive" className="container-fluid py-5 border-top border-1 border-white">
      <div className="row justify-content-evenly">
        <div className="col-11 col-md-7 px-0">
        <div className="">
          <div className="" data-aos="fade-up">
          <ImageGallery className="transition" items={images} />
          </div>
          <DetailsOfListing
          Title="APARTMENT FOR SALE IN PEARLZ BY DANUBE, AL FURJAN"
          Title2="VASTU | COMMUNITY VIEW | U SHAPE KITCHEN | METRO"
          Price="1,599,999"
          Location="Dubai"
          Category="Apartment"
          Beds="2 + Maid"
          Baths="2"
          Area="1,085 sqft / 101 sqm"
          FeaturesAndAmenities={<>
            <FeaturesAndAmenities Title="Study" />
            <FeaturesAndAmenities Title="Central A/C" />
            <FeaturesAndAmenities Title="Balcony" />
            <FeaturesAndAmenities Title="Shared Pool" />
            <FeaturesAndAmenities Title="Shared Spa" />
            <FeaturesAndAmenities Title="Security" />
            <FeaturesAndAmenities Title="Covered Parking" />
            <FeaturesAndAmenities Title="Built in Wardrobes" />
            <FeaturesAndAmenities Title="Kitchen Appliances" />
            <FeaturesAndAmenities Title="Shared Gym" />
            <FeaturesAndAmenities Title="Lobby in Building" />
            <FeaturesAndAmenities Title="Children's Pool" />
            <FeaturesAndAmenities Title="Children's Play Area" />
            <FeaturesAndAmenities Title="Barbecue Area" />  
            <FeaturesAndAmenities Title="Maids Room" />  
            <FeaturesAndAmenities Title="View of Landmark" />  
            <FeaturesAndAmenities Title="Vastu-compliant" />  
            </>}
          Description={<>
         Apartment for sale in Pearlz by Danube, Al Furjan
         <div className="py-2" />
         Welcome to your future home in the heart of Dubai—an exclusive, brand new, fully furnished 2-bedroom apartment with an office at the prestigious Pearlz by Danube. Designed for those who seek luxury, convenience, and harmony, this residence is not just a place to live but a space where dreams flourish.
         <div className="py-2" />
         Property Details: <br />
         VASTU Complaint <br />
         Brand New Condition <br />
         Size: 1085.75 sqft <br />
         2Bed Plus Office <br />
         2Full Washrooms <br />
         U Shaped Kitchen <br />
         Fully & Well Furnished <br />
         Near Metro <br />
         Amazing Amenities
         <div className="py-2" />
         This 2-bedroom apartment with an office at Pearlz by Danube is not just a place to live—it's a lifestyle choice. With its exclusive status, brand new condition, VASTU compliance, and high-end furnishings, it offers unparalleled luxury and comfort. Whether you're looking for a family home or a prestigious address in Dubai, this property is an exceptional investment opportunity.
         <div className="py-2" />
         Seize the chance to make this exquisite apartment your own and experience the best that Dubai has to offer. Contact us today to schedule a viewing and take the first step toward your new home at Pearlz by Danube.
          </>}
          ListingDetailsSection={<>
          <ListingDetailsSection Title1="Property Type" Title2="Apartment" />          
          <ListingDetailsSection Title1="Property Size" Title2="1,085 sqft / 101 sqm" />          
          <ListingDetailsSection Title1="Bedrooms" Title2="2 + Maid" />          
          <ListingDetailsSection Title1="Bathrooms" Title2="2" />     
          </>}
          />
      </div>
        </div>
        <div className="col-11 col-md-3">
          <div className="border-white border-1 border p-4 mb-4 sticky-top">
          <InquiryOfListing />
          <ShareSocialMediaIcons
          url_Email="https://800homes.eacademe.org/apartment-for-sale-dubai-al-furjan-pearlz-by-danube-12245073"
          url_Whatsapp="https://800homes.eacademe.org/apartment-for-sale-dubai-al-furjan-pearlz-by-danube-12245073"
          url_Facebook="https://800homes.eacademe.org/apartment-for-sale-dubai-al-furjan-pearlz-by-danube-12245073"
          url_Linkedin="https://800homes.eacademe.org/apartment-for-sale-dubai-al-furjan-pearlz-by-danube-12245073"
          />
          </div>
        </div>        
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default Listing8 