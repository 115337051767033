import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';
import MainPageOfListing from '../components/listing/MainPageOfListing';
import IndividualPageOfListing from '../components/listing/IndividualPageOfListing';

const IndividualListing = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  return (
            <>
        <Helmet>
            <meta charSet="utf-8" />
            <title> </title>
            <meta name="description" content="" />
            <link rel="canonical" href="https://800homes.ae/"/>
        </Helmet>
    <Header/>
    <IndividualPageOfListing/>
    <Footer/>
    </>
  );
}

export default IndividualListing;
