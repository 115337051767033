import React from 'react';
import NavBar from './NavBar';

// Sources
import Logo from "../../assets/images/800home/Logo.webp"
import { Link } from 'react-router-dom';

const DesktopHeader = () => {
  return (
    <>
    <section className="container-fluid">
        <div className="col-md-10 mx-auto d-flex align-items-center py-md-3">
          <div className="col-md-2 me-auto"> 
          <Link to="/" className="">
          <img src={Logo} className='header-logo' alt="Logo" />
          </Link>
          </div>
          <NavBar/>
      </div>
    </section>
    </>
  );
}

export default DesktopHeader;
