import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import Img800 from '../assets/images/800home/Logo.webp';
import { Helmet } from 'react-helmet';
import ProfileMobile from '../components/faraaz/ProfileMobile';
import ProfileDesktop from '../components/faraaz/ProfileDesktop';


const Profile800Homes = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>

    <Helmet>
    <meta name="robots" content="noindex"/>
    <meta charSet="utf-8" />
    <title> 800 Homes Real Estate Brokerage  </title>
    </Helmet>

    <section id="website-responsive" className="d-none d-md-block">
      <ProfileDesktop
       Image={Img800}
       DisplayDesktop="d-none"
       LogoWidth="px-4"
       Padding="mt-5 pt-xxl-5"
      Title="800 Homes"
      Designation="Real Estate Brokerage"
      PhoneNumberLink="0585746637"
      PhoneNumber="0585746637"
      WhatsappLink="971585746637"
      Whatsapp="0585746637"
      Email="admin@800homes.ae"
      />
    </section>

    <section id="website-responsive" className="d-block d-md-none">
      <ProfileMobile
       Image={Img800}
       DisplayMobile="d-none"
      Title="800 Homes"
      Designation="Real Estate Brokerage"
      PhoneNumberLink="0585746637"
      PhoneNumber="0585746637"
      WhatsappLink="971585746637"
      Whatsapp="0585746637"
      Email="admin@800homes.ae"
      />
    </section>

    </>
  );
}

export default Profile800Homes;
