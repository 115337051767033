import React, {useState, useRef} from "react"
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from 'emailjs-com'

const InquiryForm = () =>{
  const [verified, setVarified] = useState(false)
  function onChange(value) {
    console.log("Captcha value:", value);
    setVarified(true);
  }
  const refform = useRef();
  const sendForm = (e) =>{
    e.preventDefault();
  emailjs.sendForm('service_52cv4u2','800homes',e.target,'qLyy-QK10bQA32qAQ').then(res=>{
    console.log(res)
    window.location.href="/thank-you"
  }).catch(err=>{
    console.log(err)
  });
  e.target.reset()
};

    return (
    <>
    <section className="container-fluid bg-grey-2 rounded-0 border border-grey border-1 p-md-5 py-4" data-aos="fade-up">
        <div className="row justify-content-center align-items-center">
            <div className="col-12">
                <div className="" >
                <form ref={refform} onSubmit={sendForm} id="contactForm" method="POST" action="#" className="">
      <div className="form-group mb-3">
        <input type="text" className="form-control text-grey fs-ss-16 fw-300" id="name" name="name" placeholder="Name *" required/>
      </div>
      <div className="form-group mb-3">
        <input type="email" className="form-control text-grey fs-ss-16 fw-300" id="email" name="email" placeholder="Email *" required/>
      </div>
      <div className="form-group mb-3">
        <input type="number" className="form-control text-grey fs-ss-16 fw-300" id="phone_number" name="phone_number" placeholder="Phone Number *" required/>
      </div>
      <div className="form-group mb-4">
        <textarea className="form-control text-grey fs-ss-16 fw-300" id="message" name="message" rows="3" placeholder="Message"></textarea>
      </div>
      <div className="">
      <ReCAPTCHA
          sitekey="6LdX3UgqAAAAAMWqmjHfeiVGKr_oLcfyYrNEu8KH" 
          onChange={onChange}/>
          <button className='mt-4 text-center text-white fw-300 bg-ss-primary bg-hover-ss-primary rounded-0 border-0 text-ss-secondary fs-ss-16 px-3 py-ss-2 w-100'>
            Submit
          </button>
      </div>
    </form>
    </div>
                </div>
        </div>
    </section>
    </>
    )
}

export default InquiryForm;